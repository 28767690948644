<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn   }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc">
        {{thematicDesc}}
      </div>
      <div class="section_content">
        <div class="section_title section_title_range">服务范围</div>
        <div class="range_desc">
          {{sectionDesc}}
        </div>
      </div>
    </div>
    <div class="main_panel">
      <div class="section_content">
        <div class="section_title section_title_feature">服务特色</div>
        <div class="section_card_content">
          <div class="section_card" v-for="(item,idx) in features" :key="idx">
            <div class="section_card_pic">
              <img :src="item.icon" alt="">
            </div>
            <div class="section_card_title">{{item.title}}</div>
            <div class="section_card_desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data() {
    return {
      "thematicDesc": "",
      "sectionDesc": "",
      "features": [],
      "titleZh": "",
      "titleEn": ""
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeService&secondaryTitle=generalContractingService`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.thematicDesc = configData.thematicDesc;
          _this.sectionDesc = configData.sectionDesc;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
          _this.features = configData.features.map(i=>{
            return Object.assign({},i,{
              "icon":`${window.PICSEVERURL}/${i.icon}`
            })
          });
        }else{
          _this.$notify({
            title: '失败',
            message: "总包服务数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData();
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .topic_panel {
    position: relative;
    margin-bottom: 3rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    width: 100%;
    margin-bottom: 43px;
    .module_desc {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      line-height: 1.4rem;
      margin-bottom: 2rem;
    }
    .range_desc {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 32px;
      margin-bottom: 20px;
      margin-top: 22px;
    }
    .section_card_content {
      width: 100%;
      // display: flex;
      // justify-content: space-between;
    }
    .section_content {
      width: 100%;
      margin-bottom: 34px;
      .section_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.2rem;
        color: #000000;
        height: 2rem;
        line-height: 2rem;
        padding-left: 3rem;
        box-sizing: border-box;
        &.section_title_feature{
          background: url('./images/titleBg@feature.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
        &.section_title_range{
          background: url('./images/titleBg@range.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
      }
    }
    .section_card {
      width: 100%;
      background: #f6f7f9;
      box-sizing: border-box;
      padding: 0.5rem;
      margin: 0.5 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      // justify-content: center;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .section_card_pic {
        position: relative;
        width: 5rem;
        height: 5rem;
        background-size: 100% auto;
        background-position: center left;
        margin-top: 1.5rem;
        img{
          width: 100%;
          object-fit: cover;
        }
      }
      .section_card_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.2rem;
        color: #000000;
        line-height: 53px;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
      .section_card_desc {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #666666;
        line-height: 1.4rem;
        text-align: center;
      }
    }
    .section_module_content {
      margin-bottom: 1rem;
      width: 100%;
      .module_items {
        display: flex;
        margin-bottom: 1.2rem;
        // justify-content: space-between;
      }
      .modules_part {
        padding: 0.5rem 0 0 1.5rem;
      }
      .modules_icon {
        width: 3.5rem;
        height: 3.5rem;
        background-size: 100% auto;
        background-position: center left;
        // margin-right: 7px;
      }
      .modules_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.2rem;
        color: #333333;
        line-height: 1.4rem;
      }
      .modules_desc {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #333333;
        line-height: 1.4rem;
        padding-top: 0.5rem;
      }
    }
  }
}
</style>